import React from "react";
import "./Video.scss";
// import promotionalVideo from "../../assets/video/video_promocional.mp4";

const Video = () => {
  return (
    <section id="video" className="container">
      <h2 id="title">Video</h2>
      <iframe
        width="100%"
        height="650px"
        src="https://www.youtube.com/embed/ah-GPcYPGEI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

      {/* <video width="100%" height="auto" controls>
            <source src={"https://youtu.be/reNi6YZ3ZI0"} type="video/mp4" />
            Tu navegador no soporta los vídeos de HTML5
          </video> */}
      {/* <iframe
            src="https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
       
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              width: "100%",
              height: "650px",<iframe width="560" height="315" src="https://www.youtube.com/embed/zw4OjNsYm6o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            }}
            title="Fundo Armadillo"
          ></iframe> */}
    </section>
  );
};

export { Video };
