import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./Proyecto.scss";
import proyectImage from "../../assets/img/imgsGaleria/2.png";
// import promotionalVideo from "../../assets/video/video_promocional.mp4";

const Proyecto = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  // const promotionalVideo =
  //   "https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
  const handleClose = () => {
    setShowVideoModal(false);
  };

  return (
    <section id="proyecto" className="container">
      <Modal show={showVideoModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="650px"
            src="https://www.youtube.com/embed/reNi6YZ3ZI0"
            title="Ríos de Maullín"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          {/* <video width="100%" height="auto" controls>
            <source src={"https://youtu.be/reNi6YZ3ZI0"} type="video/mp4" />
            Tu navegador no soporta los vídeos de HTML5
          </video> */}
          {/* <iframe
            src="https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"

            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              width: "100%",
              height: "650px",
            }}
            title="Fundo Armadillo"
          ></iframe> */}
        </Modal.Body>
        <Modal.Footer id="galleryModalFooter">
          <Button variant="dark" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="contentContainer">
        <div id="textContainer">
          <h2 id="title">Sobre el proyecto</h2>
          <h5>
            Proyecto Ríos de Maullín es un proyecto con 19 parcelas ubicadas a
            30 minutos Puerto Montt, en la zona de Maullín. A 25 minutos del
            aeropuerto y rodeada por mas de 7 ríos de la zona.
            <br /> <br />
            Cuenta con parcelas planas, con vertientes naturales, además de
            vegetación las cuales te permiten apreciar toda la maravilla del sur
            de chile.
            <br /> <br />
            Se encuentra a minutos de ríos de pesca, los cuales son visitados
            año a año por turistas y a minutos del futuro puente del Chacao para
            acceder de manera inmediata a la belleza de Chiloé.
            <br /> <br />
            El mundial de surf, el próximo puerto de cruceros y el puente hacia
            Chiloé son algunas de las características que hacen de este lugar
            una zona estratégica.
          </h5>
          {/* <Button
            id="videoButton"
            variant="dark"
            size="lg"
            onClick={() => setShowVideoModal(true)}
          >
            Ver video del proyecto
          </Button> */}
        </div>
        <img id="imagenProyecto" src={proyectImage} alt="imagen-proyecto" />
      </div>
    </section>
  );
};

export { Proyecto };
