import React, { useState } from "react";
import "./Galeria.scss";
import image1 from "../../assets/img/imgsGaleria/1.jpg";
import image2 from "../../assets/img/imgsGaleria/2.png";
import image3 from "../../assets/img/imgsGaleria/3.png";
import image4 from "../../assets/img/imgsGaleria/4.png";
import image5 from "../../assets/img/imgsGaleria/5.png";
import image6 from "../../assets/img/imgsGaleria/6.png";
import image7 from "../../assets/img/imgsGaleria/7.png";
import image8 from "../../assets/img/imgsGaleria/8.png";
import image9 from "../../assets/img/imgsGaleria/9.png";
import image10 from "../../assets/img/imgsGaleria/10.png";
import image1mini from "../../assets/img/minisGaleria/1.jpg";
import image2mini from "../../assets/img/minisGaleria/2.png";
import image3mini from "../../assets/img/minisGaleria/3.png";
import image4mini from "../../assets/img/minisGaleria/4.png";
import image5mini from "../../assets/img/minisGaleria/5.png";
import image6mini from "../../assets/img/minisGaleria/6.png";
import image7mini from "../../assets/img/minisGaleria/7.png";
import image8mini from "../../assets/img/minisGaleria/8.png";
import image9mini from "../../assets/img/minisGaleria/9.png";
import image10mini from "../../assets/img/minisGaleria/10.png";

import { ModalGaleria } from "./ModalGaleria";

const Galeria = () => {
  const [showModal, setShowModal] = useState(false);
  const [imgOnModal, setImgOnModal] = useState(null);
  const [imgOnModalIndex, setImgOnModalIndex] = useState(null);

  const handleClose = () => setShowModal(false);

  const handleShow = (i, img) => {
    setImgOnModal(img);
    setImgOnModalIndex(i);
    setShowModal(true);
  };

  const images = [
    {
      img: image1,
      mini: image1mini,
    },
    {
      img: image2,
      mini: image2mini,
    },
    {
      img: image3,
      mini: image3mini,
    },
    {
      img: image4,
      mini: image4mini,
    },
    {
      img: image5,
      mini: image5mini,
    },
    {
      img: image6,
      mini: image6mini,
    },
    {
      img: image7,
      mini: image7mini,
    },
    {
      img: image8,
      mini: image8mini,
    },
    {
      img: image9,
      mini: image9mini,
    },
    {
      img: image10,
      mini: image10mini,
    },
  ];

  return (
    <section id="galeria" className="container">
      <ModalGaleria
        show={showModal}
        imgsArray={images}
        actualImageIndex={imgOnModalIndex}
        actualImage={imgOnModal}
        handleClose={handleClose}
      />
      <h2 id="title">Galería del Proyecto</h2>
      <div id="containerGaleria">
        {images.map((img, i) => (
          <img
            onClick={() => handleShow(i, img.img)}
            key={i}
            src={img.mini}
            className="fluid galleryImage"
            alt="img"
            id="imgGaleria"
          />
        ))}
      </div>
    </section>
  );
};

export { Galeria };
