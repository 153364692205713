import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./Home.scss";
import { Proyecto } from "../Proyecto";
import { Video } from "../Video";
import { Caracteristicas } from "../Caracteristicas";
import { TourVirtual } from "../TourVirtual";
import { Sector } from "../Sector";
import { Galeria } from "../Galeria";
import { Contacto } from "../Contacto";
import homeVideo from "../../assets/video/video-header-nuevo.mp4";
import videoPoster from "../../assets/img/video-poster-img.jpg";
// import { CustomModal } from "../../utils/CustomModal";

const Home = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const storageInfo = localStorage.getItem("userCodeObject");
    const parseStorageInfo = JSON.parse(storageInfo);
    if (parseStorageInfo && parseStorageInfo.userCodeEntered) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, []);

  return (
    <React.Fragment>
      {/* <CustomModal show={showModal} setShow={setShowModal} /> */}
      <div id="home" className="container">
        <video id="background-video" autoPlay loop muted poster={videoPoster}>
          <source src={homeVideo} />
        </video>
        <h1 id="title">
          <span id="preTitle">Ríos de Maullín</span>
        </h1>
        <h4 id="subtitle">
          Disfruta la belleza de los ríos del sur de Chile a minutos de ti.
          <br />
          Lanzamiento Privado, sólo por invitación de referidos.
        </h4>
        <Button
          className="homeButton"
          variant="outline-light"
          size="lg"
          href="#caracteristicas"
        >
          Más información
        </Button>
        <Button
          className="homeButton"
          variant="dark"
          size="lg"
          href="#contacto"
        >
          Contáctanos
        </Button>
        <div id="homeCardsContainer" className="container">
          <div className="homeCard">
            <h3>El Proyecto</h3>
            <p>
              19 parcelas rodeadas de mas de 7 ríos de pesca. Ven a disfrutar
              con tu familia de la naturaleza preservando lo esencial del sur de
              Chile. Sólo puedes acceder si eres invitado de familias que ya han
              comprado, esto con la finalidad de mantener el sentido familiar y
              natural del lugar junto a tus vecinos.
            </p>
          </div>
          <div className="homeCard">
            <h3>Cerca de Todo</h3>
            <p>
              Gracias a su cercanía con el aeropuerto y al centro de la ciudad
              encontrarás lo que necesitas a sólo minutos. Podrás obtener el
              lugar que buscas para conectarte con la tierra en un hermoso
              entorno natural. Carelmapu y su mundial de surf, el futuro puente
              que unirá hacia Chiloé y los Ríos San Pedro, Huimán y Cariquilda
              hacen de este proyecto un lugar único.
            </p>
          </div>
          <div className="homeCard">
            <h3>Ubicación</h3>

            <p>
              Proyecto Ríos de Maullín es un proyecto rural ubicado a 10 minutos
              en auto del pueblo de Maullín, a pasos del centro de Puerto Mont,
              rodeado de los sectores mas bellos del sur de chile, el cual
              buscará preservar todo el sentido natural para que tu y tu familia
              puedan disfrutar de nuestra tierra.
            </p>
          </div>
        </div>
        <div id="homeGeneralCard">
          <h3>El Proyecto</h3>
          <p>
            19 parcelas rodeadas de mas de 7 ríos de pesca. Ven a disfrutar con
            tu familia de la naturaleza preservando lo esencial del sur de
            Chile. Sólo puedes acceder si eres invitado de familias que ya han
            comprado, esto con la finalidad de mantener el sentido familiar y
            natural del lugar junto a tus vecinos.
          </p>
          <h3 className="generalTitle">Cerca de Todo</h3>
          <p>
            Gracias a su cercanía con el aeropuerto y al centro de la ciudad
            encontrarás lo que necesitas a sólo minutos. Podrás obtener el lugar
            que buscas para conectarte con la tierra en un hermoso entorno
            natural. Carelmapu y su mundial de surf, el futuro puente que unirá
            hacia Chiloé y los Ríos San Pedro, Huimán y Cariquilda hacen de este
            proyecto un lugar único.
          </p>
          <h3 className="generalTitle">Ubicación</h3>
          <p>
            Proyecto Ríos de Maullín es un proyecto rural ubicado a 10 minutos
            en auto del pueblo de Maullín, a pasos del centro de Puerto Mont,
            rodeado de los sectores mas bellos del sur de chile, el cual buscará
            preservar todo el sentido natural para que tu y tu familia puedan
            disfrutar de nuestra tierra.
          </p>
        </div>
      </div>
      <Proyecto />
      <Video />
      <Caracteristicas />
      <TourVirtual />
      {/* <Sector /> */}
      <Galeria />
      <Contacto />
    </React.Fragment>
  );
};

export { Home };
