import React from "react";
import { Button, Image } from "react-bootstrap";
import "./Caracteristicas.scss";
import mapImage from "../../assets/img/mapa.png";
import logo from "../../assets/img/favicon.png";

const Caracteristicas = () => {
  const features = [
    { text: "Listas para escriturar." },
    { text: "Parcelas desde 5000 m2." },
    {
      text: "Acceso en cualquier tipo de vehículo directo desde el aeropuerto. (25 minutos).",
    },
    { text: "5 Parcelas con orilla de camino." },
    { text: "Rodeado por mas de 7 ríos de la Zona." },
    { text: "Topografía plana y semiplana." },
    { text: "Factibilidad de Electricidad y Agua." },
    {
      text: "Ubicado a 30 minutos del aeropuerto del Tepual de Puerto Montt. ",
    },
    {
      text: "A 40 minutos del centro de la ciudad de Puerto Montt e inicio de la Carretera Austral",
    },
    {
      text: "10 minutos del centro urbano de Maullín",
    },
    { text: "20 minutos del futuro Puente del Chacao" },
    { text: "8 minutos de la confluencia del río Maullín, zona de pesca." },
    { text: "15 minutos de Carelmapu y su Mundial de Surf." },
    { text: "A 60 minutos de la famosa Carretera Austral." },
  ];

  return (
    <section id="caracteristicas" className="container">
      <h2 id="title">Características y Ubicación</h2>
      <div id="contentContainer">
        <div id="mapContainer">
          <Image id="mapImage" src={mapImage} alt="mapa" />
          <a
            href="https://goo.gl/maps/SZQBnCAjLyQHiELN9"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="dark" size="lg">
              Abrir en Google Maps
            </Button>
          </a>
        </div>
        <div id="caracteristicasContainer">
          {features.map((f, i) => (
            <div key={i} className="caracteristicaContainer">
              <h4 className="caracteristicaIcon">
                <Image id="listIcon" src={logo} alt="logo" />
              </h4>
              <h5>{f.text}</h5>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Caracteristicas };
